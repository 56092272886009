import TestRoutes from './test/_routes';
import session, { getActiveSession } from '@cognitoforms/api/services/session';

export default [
	...TestRoutes,
	{
		path: '/404',
		name: 'page-not-found',
		component: () => import(/* webpackChunkName: 'PageNotFound' */ 'src/views/PageNotFound.vue')
	},
	{
		path: 'myaccount',
		component: () => import(/* webpackChunkName: 'Settings' */ './Admin.vue'),
		meta: {
			auth: 'required',
			isRouterLink: true
		},
		children: [
			{
				path: '',
				name: 'account-settings',
				component: () => import(/* */ 'src/views/settings/user/AccountSettings.vue'),
				meta: {
					auth: 'required',
					isRouterLink: true,
					useSettingsLayout: true,
					sections: [
						{
							id: '#profile',
							displayName: 'Profile'
						},
						{
							id: '#organizations',
							displayName: 'Organizations'
						},
						{
							id: '#delete-account',
							displayName: 'Delete Account'
						}
					]
				}
			}
		]
	},
	{
		path: '/forms/saveandcontinue',
		name: 'save-and-continue',
		component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
		props: route => ({ ...route.query, ...route.params }),
		meta: {
			auth: 'required'
		}
	},
	{
		path: '/:orgcode/settings/invoice',
		component: () => import(/* webpackChunkName: 'Settings' */ './Admin.vue'),
		meta: {
			auth: 'required'
		},
		children: [
			{
				path: ':id',
				name: 'organization-settings-invoice',
				component: () => import(/* webpackChunkName: 'Invoice' */ './Invoice.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					defaultCollapsedNav: true,
					isRouterLink: true,
					hideHeaderNav: true
				}
			}
		]
	},
	{
		path: '/:orgcode/settings/selectplan',
		component: () => import(/* webpackChunkName: 'Settings' */ './Admin.vue'),
		meta: {
			auth: 'required'
		},
		children: [
			{
				path: '',
				name: 'organization-settings-select-plan',
				component: () => import(/* webpackChunkName: 'SelectPlan' */ 'src/views/pricing/SelectPlan.vue'),
				props: true,
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					isRouterLink: true,
					defaultCollapsedNav: true,
					loadManifest: true
				}
			}
		]
	},
	{
		path: '/:orgcode/settings',
		meta: {
			auth: 'required'
		},
		component: () => import(/* webpackChunkName: 'Settings' */ './Admin.vue'),
		children: [
			{
				path: '',
				name: 'organization-settings',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationGeneralSettings.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true
				}
			},
			{
				path: 'general',
				name: 'organization-settings-general',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationGeneralSettings.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'General'
				}
			},
			{
				path: 'plan',
				name: 'organization-settings-plan',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationPlanAndBilling.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					useSettingsLayout: true,
					displayName: 'Plan & Billing'
				}
			},
			{
				path: 'usage',
				name: 'organization-settings-usage',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationUsagePage.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Usage'
				}
			},
			{
				path: 'users',
				name: 'organization-settings-users',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationUsersAndAuthentication.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Users & Authentication'
				}
			},
			{
				path: 'guest-access',
				name: 'organization-settings-guest-access',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationGuestAccess.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Guest Access'
				}
			},
			{
				path: 'email-domains',
				name: 'organization-settings-email-domains',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationEmailDomains.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Email Domains'
				}
			},
			{
				path: 'integrations',
				name: 'organization-settings-integrations',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/IntegrationsList.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Integrations'
				},
				beforeEnter: async (to, _, next) => {
					const session = await getActiveSession();
					to.params.useAppNav = session?.enabledFeatures?.AppNav ?? false;
					next();
				}
			},
			{
				path: 'referrals',
				name: 'organization-settings-referrals',
				component: () => import(/* webpackChunkName: 'Settings' */ '../settings/organization/OrganizationReferralSettings.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					useSettingsLayout: true,
					displayName: 'Referrals'
				}
			},
			{
				path: 'preview-features',
				name: 'organization-settings-preview-features',
				component: () => import(/* webpackChunkName: 'Settings' */ 'src/views/organization/FeaturePreview.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					useSettingsLayout: true,
					displayName: 'Preview Features'
				},
				beforeEnter: async (to, _, next) => {
					const session = await getActiveSession();
					to.params.useAppNav = session?.enabledFeatures?.AppNav ?? false;
					to.params.organizationId = session?.organizationId ?? '';
					to.params.orgCode = session?.orgCode ?? '';
					to.params.userEmail = session?.userEmail ?? '';
					to.params.userName = session?.userName ?? '';
					next();
				}
			},
			{
				path: 'subscription',
				name: 'organization-settings-subscription-settings',
				component: () => import(/* webpackChunkName: 'Settings' */ 'src/views/settings/organization/ChangePlan.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					useSettingsLayout: false,
					defaultCollapsedNav: true
				}
			},
			{
				path: 'users/:id',
				name: 'organization-settings-profile-settings',
				component: () => import(/* webpackChunkName: 'ProfileSettings' */ './ProfileSettings.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator',
					isRouterLink: true,
					useSettingsLayout: true
				}
			},
			{
				path: 'change-plan-confirmation/:id',
				name: 'organization-settings-change-plan-confirmation',
				component: () => import(/* webpackChunkName: 'ChangePlanConfirmation' */ './ChangePlanConfirmation.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					isRouterLink: true,
					useSettingsLayout: false,
					defaultCollapsedNav: true
				}
			},
			{
				path: 'prepay-check-confirmation/:id',
				name: 'organization-settings-prepay-check-confirmation',
				component: () => import(/* webpackChunkName: 'PrepayCheckConfirmation' */ './PrepayCheckConfirmation.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Owner',
					isRouterLink: true,
					useSettingsLayout: true
				}
			},
			{
				path: 'global-store-debug',
				name: 'global-store-debug',
				component: () => import(/* webpackChunkName: 'GlobalStoreDebug' */ './developer/GlobalStoreDebug.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					isRouterLink: true
				}
			},
			{
				path: ':catchAll(.*)',
				name: 'organization-settings-other',
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					minimumRole: 'Administrator'
				},
				beforeEnter(to, from, next) {
					next({ name: 'organization-settings-general', params: to.params, query: to.query });
				}
			}
		]
	},
	{
		path: '/:orgcode',
		component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
		meta: { auth: 'required' },
		children: [
			{
				path: '',
				name: 'forms',
				component: () => import(/* webpackChunkName: 'FormsDashboard' */ './FormsDashboard.vue'),
				props: route => ({ ...route.query, ...route.params }),
				async beforeEnter(to, from, next) {
					if (session.value && session.value.enabledFeatures.AppNav) {
						const components = await import(/* webpackChunkName: 'AppHome' */ './AppHome.vue');
						to.matched[to.matched.length - 1].components = components;
					}
					next();
				},
				meta: {
					auth: 'required',
					isRouterLink: true,
					showLoadingIndicator: true,
					showGlobalErrors: true,
					mobileMenu: true,
					showAppNavOptIn: true
				}
			},
			{
				path: 'home',
				name: 'home',
				component: () => import(/* webpackChunkName: 'FormsDashboard' */ './AppHome.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: { auth: 'required', showGlobalErrors: true }
			},
			{
				path: 'tasks',
				name: 'task-dashboard',
				component: () => import(/* webpackChunkName: 'TaskDashboard' */ './TaskDashboard.vue'),
				async beforeEnter(to, from, next) {
					if (session.value && session.value.enabledFeatures.TaskDashboard && session.value.enabledFeatures.AppNav)
						next();
					else
						next('/' + to.params.orgcode);
				},
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}
			},
			{
				path: 'new',
				name: 'builder-new',
				component: () => import(/* webpackChunkName: 'FormBuilder' */ './FormBuilder.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					isRouterLink: true,
					showLoadingIndicator: true,
					fauxModal: true
				}
			},
			{
				path: ':internalFormName/build',
				name: 'builder',
				component: () => import(/* webpackChunkName: 'FormBuilder' */ './FormBuilder.vue'),
				props: route => ({ ...route.query, ...route.params }),
				beforeEnter(to, from, next) {
					if (session.value && session.value.enabledFeatures.AppNav && to.path.endsWith('/'))
						next({ ...to, path: to.path.slice(0, -1) });
					else
						next();
				},
				meta: {
					auth: 'required',
					isRouterLink: true,
					showLoadingIndicator: true,
					fauxModal: true,
					getFallbackRoute: getBuildPublishFallbackRoute
				}
			},
			{
				path: ':internalFormName/publish',
				name: 'publish',
				component: () => import(/* webpackChunkName: 'PublishPage' */ './PublishPage.vue'),
				props: route => ({ ...route.query, ...route.params }),
				beforeEnter(to, from, next) {
					if (session.value && session.value.enabledFeatures.AppNav && to.path.endsWith('/'))
						next({ ...to, path: to.path.slice(0, -1) });
					else
						next();
				},
				meta: {
					auth: 'required',
					isRouterLink: true,
					showLoadingIndicator: true,
					fauxModal: true,
					getFallbackRoute: getBuildPublishFallbackRoute
				}
			},
			{
				path: 'entryemails/:entryId',
				name: 'entry-emails',
				component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}

			},
			{
				path: 'updateemailstatuses/:entryId',
				name: 'update-email-statuses',
				component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}
			},
			{
				path: ':internalFormName/tosviolation',
				alias: '/forms/tosviolation',
				name: 'tos-violation',
				component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}
			},
			{
				path: ':internalFormName/passwordviolation',
				alias: '/forms/passwordviolation',
				name: 'password-violation',
				component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}
			},
			{
				path: ':internalFormName/ccviolation',
				alias: '/forms/ccviolation',
				name: 'cc-violation',
				component: () => import(/* webpackChunkName: 'Admin' */ './Admin.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required'
				}
			},
			{
				path: ':internalFormName/(entries/)?:entryView?/:entryNumber?',
				name: 'entries',
				component: () => import(/* webpackChunkName: 'EntriesPage' */ './EntriesPage.vue'),
				props: route => ({ ...route.query, ...route.params }),
				meta: {
					auth: 'required',
					isRouterLink: true,
					showLoadingIndicator: true,
					customReadyEvent: true,
					showGlobalErrors: true
				},
				beforeEnter: async (to, _, next) => {
					const session = await getActiveSession();

					if (session?.enabledFeatures?.AppNav && (to.params.pathMatch === 'entries/' || to.params.entryView === 'entries')) {
						delete to.params.pathMatch;
						if (to.params.entryView === 'entries')
							delete to.params.entryView;
						next(to);
					}
					else
						next();
				}
			}
		]
	}
];

function getBuildPublishFallbackRoute(router) {
	const orgCode = router.currentRoute.params.orgcode;
	const formName = router.currentRoute.params.internalFormName;
	return router.match(`${orgCode}/${formName}/entries`);
}
