import type { UserFormAndFolderMeta } from '@cognitoforms/types/server-types/model/user-form-and-folder-meta';
import type { UserMeta } from '@cognitoforms/types/server-types/model/user-meta';
import type { OrganizationMeta } from '@cognitoforms/types/server-types/model/organization-meta';
import type { UserAndAdminFolderMetas } from '@cognitoforms/types/server-types/model/user-and-admin-folder-metas';
import type { GlobalStoreMeta } from '@cognitoforms/types/server-types/model/global-store-meta';
import { serviceRequest } from './service-request';

export function getUserMeta(): Promise<UserMeta> {
	return serviceRequest('svc/entity-meta/user', null, { method: 'GET' });
}

export function getOrganizationMeta(): Promise<OrganizationMeta> {
	return serviceRequest('svc/entity-meta/organization', null, { method: 'GET' });
}

export async function getFormMeta(formIds: string[]): Promise<UserFormAndFolderMeta> {
	return serviceRequest('svc/entity-meta/forms', { formIds }, { method: 'POST' });
}

export async function getFolderMeta(folderIds: string[]): Promise<UserAndAdminFolderMetas> {
	return serviceRequest('svc/entity-meta/folders', { folderIds }, { method: 'POST' });
}

export async function getGlobalStore(): Promise<GlobalStoreMeta> {
	return serviceRequest('svc/entity-meta/initialize', null, { method: 'GET' });
}