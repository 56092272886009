import { changeEmail } from '@cognitoforms/api/services/authentication';
import { CognitoConfiguration } from '@cognitoforms/utils/clientside-configuration';

export default [{
	path: '/auth',
	component: () => import(/* webpackChunkName: 'Auth' */ 'src/layouts/Auth.vue'),
	children: [
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackChunkName: 'Auth' */ './Login.vue'),
			props: route => ({ ...route.query, ...route.params }),
			async beforeEnter(to, from, next) {
				if (CognitoConfiguration.NewLoginEnabled) {
					const components = await import(/* webpackChunkName: 'AuthContainer' */ './AuthContainer.vue');
					to.matched.at(-1).components = components;
				}
				next();
			},
			meta: {
				title: 'Log In'
			}
		},
		{
			path: '/signup',
			name: 'signup',
			component: () => import(/* webpackChunkName: 'Auth' */ './Signup.vue'),
			props: route => ({ ...route.query, ...route.params }),
			async beforeEnter(to, from, next) {
				if (CognitoConfiguration.NewLoginEnabled) {
					const components = await import(/* webpackChunkName: 'AuthContainer' */ './AuthContainer.vue');
					to.matched.at(-1).components = components;
				}
				next();
			},
			meta: {
				title: 'Sign Up'
			}
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: () => import(/* webpackChunkName: 'ForgotPassword' */ './ForgotPassword.vue'),
			async beforeEnter(to, from, next) {
				if (CognitoConfiguration.NewLoginEnabled)
					location.assign('/404');
				else
					next();
			},
			meta: {
				title: 'Recover Password'
			}
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import(/* webpackChunkName: 'ResetPassword' */ './ResetPassword.vue'),
			props: route => ({ ...route.query, ...route.params }),
			async beforeEnter(to, from, next) {
				if (CognitoConfiguration.NewLoginEnabled && to.query.code) {
					const codeParam = to.query.code;
					const query = Object.assign({}, to.query);
					delete query.code;

					next({ path: '/login', query: { resetCode: codeParam, ...query } });
				}
				else
					next();
			},
			meta: {
				title: 'Reset Your Password'
			}
		},
		{
			path: '/verify-email-change',
			name: 'verify-email-change',
			component: () => import(/* webpackChunkName: 'VerifyEmailChange' */ './VerifyEmailChange.vue'),
			props: route => ({ ...route.query, ...route.params }),
			beforeEnter: (to, from, next) => {
				// If the user is verifying an email address change, change their email and continue to My Account page.
				if (to.query.code) {
					changeEmail(to.query.code)
						.then(res => {
							// successful log in
							next({ path: '/myaccount', query: { code: to.query.code } });
						}).catch(() => {
							next({ path: '/verify-email-change', query: { invalidCode: true } }); // Show error message if invalid
						});
				}
				else {
					next();
				}
			}
		},
		// [NewLogin] Remove /invitation and /accept-invite-login endpoint
		{
			path: '/invitation',
			name: 'accept-invite',
			component: () => import(/* webpackChunkName: 'AcceptInvite' */ './AcceptInvite.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Log In'
			}
		},
		{
			path: '/accept-invite-login',
			name: 'accept-invite-login',
			component: () => import(/* webpackChunkName: 'AcceptInviteLogin' */ './AcceptInviteLogin.vue'),
			props: route => ({ ...route.query, ...route.params }),
			meta: {
				title: 'Log In',
				showBackButton: true
			}
		},
		{
			path: '/api-connection',
			name: 'api-connection',
			component: () => import(/* webpackChunkName: 'ApiConnection' */ './ApiConnection.vue'),
			props: route => ({ ...route.query, ...route.params })
		},
		{
			path: '/enable-multifactor',
			name: 'enable-multifactor',
			component: () => import(/* webpackChunkName: 'EnableMultifactor' */ './EnableMultifactor.vue'),
			props: route => ({ ...route.query, ...route.params }),
			beforeEnter(to, from, next) {
				if (!CognitoConfiguration.NewLoginEnabled)
					location.assign('/404');
				else
					next();
			},
			meta: {
				title: 'Two-Factor Authentication'
			}
		}
	]
}];