import { getUserMeta, getOrganizationMeta, getFormMeta, getFolderMeta, getGlobalStore } from '@cognitoforms/api/services/entity-meta-service';
import { archiveForms, unarchiveForms, deleteForm, copyForm, moveForms, type FormCopyMode } from '@cognitoforms/api/services/form-service';
import { createFolder, deleteFolder, restoreFolder, archiveFolder, renameFolder } from '@cognitoforms/api/services/folder-service';
import { getUserPins, pinItem, renameUserPin, unpinItem } from '@cognitoforms/api/services/user-pin-service';
import { addRecentPage, getRecentPages } from '@cognitoforms/api/services/page-history-service';
import { copyEntryView, deleteEntryView, renameEntryView } from '@cognitoforms/api/services/entry-view-service';
import type { UserFolderMeta } from '@cognitoforms/types/server-types/model/user-folder-meta';
import type { OrganizationMeta } from '@cognitoforms/types/server-types/model/organization-meta';
import type { UserEntryViewMeta } from '@cognitoforms/types/server-types/model/user-entry-view-meta';
import type { UserFormMeta } from '@cognitoforms/types/server-types/model/user-form-meta';
import type { UserMeta } from '@cognitoforms/types/server-types/model/user-meta';
import type { RecentPage } from '@cognitoforms/types/server-types/model/recent-page';
import type { FormCopyInfo } from '@cognitoforms/types/server-types/forms/model/form-copy-info';
import { getAvatarSVG } from '@cognitoforms/utils/avatar';
import type { DeepReadonly } from 'vue';
import Vue, { reactive, set } from 'vue';
import type VueRouter from 'vue-router';
import { PageType } from '@cognitoforms/types/server-types/model/recent-page+-page-type';
import { ViewType } from '@cognitoforms/types/server-types/forms/model/view-type';
import type { UserPin } from '@cognitoforms/types/server-types/model/user-pin';
import { UserPinType } from '@cognitoforms/types/server-types/model/user-pin-type';
import { Deferred } from 'src/utilities/deferred';
import { ClientRole } from '@cognitoforms/types/server-types/model/client-role';

export interface GlobalState {
	loaded: boolean;
	user: UserMeta;
	organization: OrganizationMeta;
	folders: Record<string, UserFolderMeta>,
	forms: Record<string, UserFormMeta>,
	allForms: Record<string, UserFormMeta>,
	allFolders: Record<string, UserFolderMeta>,
	recentPages: RecentPage[],
	userPins: UserPin[],
	cachedCurrentForm: UserFormMeta,
	cachedCurrentView: UserEntryViewMeta,
	currentFormId: string,
	currentViewId: string,
	ready: Deferred<void>
}

const globalState = reactive<GlobalState>({
	loaded: false,
	user: null,
	organization: null,
	folders: {},
	forms: {},
	allForms: {},
	allFolders: {},
	recentPages: null,
	userPins: [],
	cachedCurrentForm: null,
	cachedCurrentView: null,
	currentFormId: null,
	currentViewId: null,
	ready: new Deferred()
});

const formUpdates = {};
const maxSafeTimeout = 2147483647;

export const globalStore = reactive({
	async initialize() {
		const result = await getGlobalStore();
		if (!result)
			return;

		globalState.user = result.UserMeta;
		globalState.organization = result.OrganizationMeta;
		globalState.userPins = result.UserPins;

		globalState.forms = Object.fromEntries(
			result.UserFormMeta.map(form => {
				form.EntryViews = this.sortBySequenceKey(form.EntryViews);
				this.scheduleFormRefresh(form);
				return [form.Id, form];
			})
		);

		globalState.allForms = Object.fromEntries(
			result.AdminFormMeta.map(form => [form.Id, form])
		);

		globalState.folders = Object.fromEntries(
			result.UserFolderMeta.map(folder => [folder.Id, folder])
		);

		globalState.allFolders = Object.fromEntries(
			result.AdminFolderMeta.map(folder => [folder.Id, folder])
		);

		globalState.loaded = true;
		globalState.ready.resolve();
	},

	get loaded(): Readonly<boolean> {
		return globalState.loaded;
	},

	get isReady(): Promise<void> {
		return globalState.ready.promise;
	},

	get fullName(): Readonly<string> {
		if (!globalState.user)
			return;

		return globalState.user.LastName
			? `${globalStore.user.FirstName} ${globalStore.user.LastName}`
			: globalStore.user.FirstName;
	},

	get user(): Readonly<UserMeta> {
		return Object.assign({}, globalState.user);
	},

	get avatar(): Readonly<string> {
		if (!globalState.user?.Avatar)
			return;

		return (globalState.user.Avatar.AvatarUrl?.trim() === '' && (globalState.user.FirstName ?? globalState.user.Email))
			? getAvatarSVG(globalState.user.Avatar.AvatarSeed, globalState.user.FirstName ?? globalState.user.Email, globalState.user.LastName ?? '')
			: globalState.user.Avatar.AvatarUrl;
	},

	get organization(): DeepReadonly<OrganizationMeta> {
		return Object.assign({}, globalState.organization);
	},

	get forms(): DeepReadonly<Record<string, UserFormMeta>> {
		return Object.assign({}, globalState.forms);
	},

	get allForms(): DeepReadonly<Record<string, UserFormMeta>> {
		return Object.assign({}, globalState.forms, globalState.allForms);
	},

	get folders(): DeepReadonly<Record<string, UserFolderMeta>> {
		return Object.assign({}, globalState.folders);
	},

	get allFolders(): DeepReadonly<Record<string, UserFolderMeta>> {
		return Object.assign({}, globalState.folders, globalState.allFolders);
	},

	get recentPages(): ReadonlyArray<RecentPage> {
		if (!globalState.recentPages)
			return null;
		return [...globalState.recentPages];
	},

	get totalTaskCount(): Readonly<number> {
		return Object.values(globalState.forms).filter(f => !f.IsArchived).reduce((sum, f) => sum + f.Tasks, 0);
	},

	get userPins(): Readonly<UserPin[]> {
		return Object.assign([], globalState.userPins);
	},

	get currentForm(): DeepReadonly<UserFormMeta & { IsDeleted: boolean }> {
		if (!globalState.currentFormId)
			return null;

		const currentForm = globalState.forms[globalState.currentFormId];
		return (currentForm) ? { ...currentForm, IsDeleted: false } : { ...globalState.cachedCurrentForm, IsDeleted: true };
	},

	get currentView(): DeepReadonly<UserEntryViewMeta & { IsDeleted: boolean }> {
		if (!globalState.currentFormId || !globalState.currentViewId)
			return null;

		const currentEntryView = this.currentForm?.EntryViews?.find(x => x.Id === globalState.currentViewId);
		return (currentEntryView) ? { ...currentEntryView, IsDeleted: false } : { ...globalState.cachedCurrentView, IsDeleted: true };
	},

	get formsWithTaskViews() : Readonly<UserFormMeta[]> {
		return Object.values(globalState.forms).filter(form => !form.IsArchived && form.EntryViews.some(entryView => entryView.IsTaskView));
	},

	get taskViews(): Readonly<UserEntryViewMeta[]> {
		return Object.values(globalState.forms).filter(f => !f.IsArchived).flatMap(f => f.EntryViews.filter(v => v.IsTaskView));
	},

	clearCurrentForm() {
		globalState.currentFormId = null;
		globalState.currentViewId = null;
	},

	setCurrentView(viewId: string) {
		if (!/\d+-\d+/.test(viewId))
			return console.error('Invalid view id provided!');

		const formId = viewId.split('-')[0];
		globalState.currentFormId = formId;
		globalState.currentViewId = viewId;
		this.updateCurrentFormCache(globalState.forms[formId]);
	},

	updateOrganizationLocally(meta: Partial<OrganizationMeta>) {
		const mergedMeta = Object.assign(globalState.organization, meta);
		set(globalState, 'organization', mergedMeta);
	},

	updateUserLocally(meta: Partial<UserMeta>) {
		const mergedMeta = Object.assign(globalState.user, meta);
		set(globalState, 'user', mergedMeta);
	},

	addFormLocally(meta: Partial<UserFormMeta> & { Id: string, InternalName: string, Name: string }) {
		const newMeta: Omit<UserFormMeta, 'FolderId' | 'ValidUntil'> = {
			EntryViews: [],
			Entries: 0,
			Tasks: 0,
			UnreadEntries: 0,
			LockedEntries: 0,
			CanManageForm: false,
			CanManageEntries: false,
			CanReviewEntries: false,
			IsArchived: false,
			IsSingleViewForm: true,
			...meta
		};

		set(globalState.forms, meta.Id, newMeta);
	},

	updateFormLocally(formId: string, meta: Partial<UserFormMeta>) {
		const mergedForm = Object.assign(globalState.forms[formId], meta);

		set(globalState.forms, formId, mergedForm);
		if (globalState.currentFormId === mergedForm.Id)
			this.updateCurrentFormCache(mergedForm);
	},

	addEntryViewLocally(meta: Partial<UserEntryViewMeta> & { Id: string, InternalName: string, Name: string, RoleId: number }) {
		const newMeta: Omit<UserEntryViewMeta, 'Token' | 'SequenceKey' | 'TaskDueDate' | 'TaskPriority'> = {
			IsTaskView: false,
			Type: ViewType.Form,
			Entries: 0,
			PreventEntryCreation: false,
			CanCreateEntry: true,
			ShowPageBreaks: true,
			IsUserSpecific: false,
			IsAssignedRole: false,
			Valid: true,
			...meta
		};

		const formId = meta.Id.split('-')[0];
		const form = globalState.forms[formId];

		const newEntryViews = [ ...form.EntryViews ];
		newEntryViews.push(newMeta as UserEntryViewMeta);
		set(form, 'EntryViews', this.sortBySequenceKey(newEntryViews));
	},

	updateEntryViewLocally(viewId: string, meta: Partial<UserEntryViewMeta>) {
		const formId = viewId.split('-')[0];
		const form = globalState.forms[formId];
		const viewIndex = form.EntryViews.findIndex(x => x.Id === viewId);
		if (viewIndex === -1) {
			this.addEntryViewLocally({ ...meta, Id: viewId } as UserEntryViewMeta);
			return;
		}

		const entryViews = [ ...form.EntryViews ];
		Object.assign(entryViews[viewIndex], meta);

		if (globalState.currentViewId === entryViews[viewIndex].Id)
			this.updateCurrentViewCache(entryViews[viewIndex]);

		set(form, 'EntryViews', this.sortBySequenceKey(entryViews));
	},

	addFolderLocally(meta: UserFolderMeta) {
		set(globalState.folders, meta.Id, meta);
	},

	updateFolderLocally(folderId: string, meta: Partial<UserFolderMeta>) {
		const mergedFolder = Object.assign(globalState.folders[folderId], meta);
		set(globalState.folders, folderId, mergedFolder);
	},

	async updateRecentPages(recentPage: RecentPage) {
		const res = await addRecentPage(recentPage);
		globalState.recentPages = res.RecentPages;
	},

	trackRecentPage(page: RecentPage, router: VueRouter) {
		// Do not track recent pages for guests
		if (globalState.organization.Role === ClientRole.Guest)
			return;

		const currentUrl = router.currentRoute;
		const isSameEntryView = () => {
			if (page.Type !== PageType.FormView && page.Type !== PageType.GridView)
				return false;

			const oldParams = currentUrl.params;
			const newParams = router.currentRoute.params;
			return oldParams.internalFormName === newParams.internalFormName && oldParams.entryView === newParams.entryView;
		};

		setTimeout(() => {
			if (currentUrl === router.currentRoute || isSameEntryView())
				this.updateRecentPages(page);
		}, 10000);
	},

	async loadUser() {
		const user = await getUserMeta();
		globalState.user = user;
	},

	async loadOrganization() {
		const organization = await getOrganizationMeta();
		globalState.organization = organization;
	},

	async loadPins() {
		const pins = await getUserPins();
		globalState.userPins = pins;
	},

	async loadForms(formIds: string[]) {
		const { Forms, Folders, AdminForms, AdminFolders } = await getFormMeta(formIds);
		const requestedForms = new Set(formIds ?? []);
		const checkFolders = new Set<string>();

		Folders.forEach(f => set(globalState.folders, f.Id, f));
		AdminFolders.forEach(f => set(globalState.allFolders, f.Id, f));
		Forms.forEach(form => {
			// Remove form from allForms if it is now fully visible to the user
			if (globalState.allForms[form.Id])
				Vue.delete(globalState.allForms, form.Id);

			form.EntryViews = this.sortBySequenceKey(form.EntryViews);

			requestedForms.delete(form.Id);

			// Get the form's folder if not already loaded
			if (form.FolderId && !globalState.folders[form.FolderId])
				checkFolders.add(form.FolderId);

			// Handle updating of statistics
			this.scheduleFormRefresh(form);
		});

		// Delete forms that were requested and not returned by the endpoint
		requestedForms.forEach(formId => {
			const folderId = globalState.forms[formId]?.FolderId;
			if (folderId)
				checkFolders.add(folderId);

			Vue.delete(globalState.forms, formId);
		});

		AdminForms.forEach(form => {
			set(globalState.allForms, form.Id, form);
			requestedForms.delete(form.Id);
		});

		// Delete forms from allForms that were requested and not returned
		requestedForms.forEach(formId => {
			Vue.delete(globalState.allForms, formId);
		});

		// Check if user still has access to folders when forms are deleted
		if (checkFolders.size > 0)
			await this.loadFolders(Array.from(checkFolders));

		// Update forms in global state after all folders have been loaded
		Forms.forEach(form => {
			set(globalState.forms, form.Id, form);
			if (globalState.currentFormId === form.Id)
				this.updateCurrentFormCache(form);
		});
	},

	async updateCurrentFormCache(form: UserFormMeta) {
		if (form) {
			set(globalState, 'cachedCurrentForm', { ...globalState.forms[form.Id] });
			const view = globalState.forms[form.Id].EntryViews.find(e => e.Id === globalState.currentViewId);
			this.updateCurrentViewCache(view);
		}
	},

	async updateCurrentViewCache(view: UserEntryViewMeta) {
		if (view)
			set(globalState, 'cachedCurrentView', { ...view });
	},

	scheduleFormRefresh(form: UserFormMeta) {
		const timestamp = new Date(form.ValidUntil).getTime();

		// Ensure date is valid and abort if it's not
		if (!form.ValidUntil || Number.isNaN(timestamp) || new Date(form.ValidUntil).getFullYear() <= 1970)
			return;

		clearTimeout(formUpdates[form.Id]);

		let timeout = Math.min(timestamp - Date.now(), maxSafeTimeout);
		if (timeout < 0)
			timeout = 10000;

		formUpdates[form.Id] = setTimeout(() => {
			this.loadForms([form.Id]);
		}, timeout);
	},

	clearFormUpdates() {
		for (const key in formUpdates)
			clearTimeout(formUpdates[key]);
	},

	async loadFolders(folderIds: string[]) {
		const { Folders, AdminFolders } = await getFolderMeta(folderIds);

		// Create a set of requested entities to track which entities were not returned
		const requestedEntities = new Set(folderIds ?? []);

		Folders.forEach(f => {
			set(globalState.folders, f.Id, f);

			// If an admin folder is now visible as a user folder, remove from allFolders
			if (globalState.allFolders[f.Id])
				Vue.delete(globalState.allFolders, f.Id);

			requestedEntities.delete(f.Id);
		});

		// Delete folders that were requested that were in folders and not returned by the endpoint
		requestedEntities.forEach(folderId => {
			Vue.delete(globalState.folders, folderId);
		});

		AdminFolders.forEach(f => {
			set(globalState.allFolders, f.Id, f);
			requestedEntities.delete(f.Id);
		});

		// Delete folders that were requested that were in allFolders and not returned by the endpoint
		requestedEntities.forEach(folderId => {
			Vue.delete(globalState.allFolders, folderId);
		});
	},

	async loadRecentPages() {
		const recentPages = await getRecentPages();
		globalState.recentPages = recentPages;
	},

	sortBySequenceKey<Meta extends { Name: string; Id: string; SequenceKey?: string }>(meta: Meta[]) {
		return meta.sort((a, b) => {
			// if transient view is present, place it first since saved views will be locked
			if (a.Id.endsWith('-0'))
				return -1;
			else if (b.Id.endsWith('-0'))
				return 1;

			// Fallback to Name without spaces if SequenceKey is not present
			const keyA = a.SequenceKey || a.Name.replace(/\s/g, '');
			const keyB = b.SequenceKey || b.Name.replace(/\s/g, '');

			return keyA.localeCompare(keyB);
		});
	},

	async pinItem(id: string, name: string = null, type: UserPinType) {
		if (!globalState.userPins.find(p => p.Id === id))
			return pinItem(id, name, type).then(() => {
				let pinId = id;
				if (type === UserPinType.EntryView && id.endsWith('-0'))
					pinId = id.replace('-0', '-1');

				const existingIndex = globalState.userPins.findIndex(p => p.Id === pinId);
				if (existingIndex < 0)
					globalState.userPins.push({ Id: id, Name: name, Type: type });
			});
		else
			return Promise.reject(new Error('Item is already pinned'));
	},

	async unpinItem(userPinId: string) {
		return unpinItem(userPinId).then(() => {
			const index = globalState.userPins.findIndex(i => i.Id === userPinId);
			Vue.delete(globalState.userPins, index);
		});
	},

	// Helper for handling 'viewless' forms when showing tree view of forms and entry views
	getEffectivePin(viewId: string) {
		const formId = viewId.split('-')[0];
		const zeroView = globalState.forms[formId]?.EntryViews?.find(view => view.Id === formId + '-0');
		if (viewId.split('-')[1] === '1' && zeroView)
			return zeroView.Id;
		return viewId;
	},

	async createFolder() {
		const defaultFolderName = 'New Folder';
		const folderId = createFolder(defaultFolderName).then((newFolder) => {
			this.addFolderLocally(newFolder);
			return newFolder.Id;
		});

		return folderId;
	},

	async copyForm(sourceOrg: string, destinationOrg : string, destinationFolderId: string, copyMode: FormCopyMode, formCopyInfo: FormCopyInfo[]) {
		return copyForm(sourceOrg, destinationOrg, destinationFolderId, copyMode, formCopyInfo).then((response) => {
			if (sourceOrg === destinationOrg) {
				const formId = formCopyInfo[0].FormId;

				this.addFormLocally({
					...globalState.forms[formId],
					Id: response.Id,
					InternalName: response.LoweredInternalName,
					Name: response.Name,
					FolderId: destinationFolderId,
					EntryViews: globalState.forms[formId].EntryViews.map(view => ({
						...view,
						Id: `${response.Id}-${view.Id.split('-')[1]}`,
						Entries: 0
					}))
				});
			}

			return response;
		});
	},

	async copyView(viewId: string, newName: string) {
		const formId = viewId.split('-')[0];
		const originalView = globalState.forms[formId].EntryViews.find(v => v.Id === viewId);
		return copyEntryView(formId, viewId, newName).then((view: { Id: string, Name: string, InternalName: string }) => {
			this.addEntryViewLocally({
				...originalView,
				Id: view.Id,
				Name: view.Name,
				InternalName: view.InternalName
			});
		});
	},

	async renameUserPin(pinId: string, newName: string) {
		const userPin = globalState.userPins.find(p => p.Id === pinId);

		if (userPin.Name === newName)
			return Promise.resolve();

		return renameUserPin(pinId, newName).then(() => {
			userPin.Name = newName;
		}).catch((e) => {
			// trigger an update if the rename fails to revert the rename in the UI
			const pinIndex = globalState.userPins.findIndex(p => p.Id === pinId);
			globalState.userPins[pinIndex] = { ...globalState.userPins[pinIndex] }[0];
		});
	},

	async renameView(viewId: string, newName: string) {
		const view = globalState.forms[viewId.split('-')[0]].EntryViews.find(v => v.Id === viewId);

		if (view.Name === newName)
			return Promise.resolve();

		const formId = viewId.split('-')[0];
		return renameEntryView(formId, viewId, newName).then(() => {
			this.updateEntryViewLocally(viewId, { Name: newName });
		}).catch((e) => {
			// trigger an update if the rename fails to revert the rename in the UI
			const viewIndex = globalState.forms[formId].EntryViews.findIndex(v => v.Id === viewId);
			globalState.forms[formId].EntryViews[viewIndex] = { ...globalState.forms[formId].EntryViews[viewIndex] }[0];
		});
	},

	async renameFolder(folderId: string, newName: string) {
		const folder = globalState.folders[folderId];

		if (folder.Name === newName)
			return Promise.resolve();

		return renameFolder(folderId, newName).then(() => {
			this.updateFolderLocally(folderId, { Name: newName });
		}).catch((e) => {
			// trigger an update if the rename fails to revert the rename in the UI
			globalState.folders[folderId] = { ...globalState.folders[folderId] }[0];
		});
	},

	async archiveForms(formIds: string[]) {
		return archiveForms(formIds).then(() => {
			formIds.forEach(formId => this.updateFormLocally(formId, { IsArchived: true }));
		});
	},

	async restoreForms(formIds: string[]) {
		return unarchiveForms(formIds).then(() => {
			formIds.forEach(formId => {
				this.updateFormLocally(formId, { IsArchived: false });

				// Ensure that folder is not archived
				const folderId = globalState.forms[formId].FolderId;
				if (globalState.folders[folderId])
					this.updateFolderLocally(folderId, { IsArchived: false });
			});
		});
	},

	async archiveFolder(folderId: string) {
		return archiveFolder(folderId).then(() => {
			const formsInFolder = Object.values(globalState.forms).filter(form => form.FolderId === folderId);
			formsInFolder.forEach(form => this.updateFormLocally(form.Id, { IsArchived: true }));
			this.updateFolderLocally(folderId, { IsArchived: true });
		});
	},

	async restoreFolder(folderId: string) {
		return restoreFolder(folderId).then(() => {
			const formsInFolder = Object.values(globalState.forms).filter(form => form.FolderId === folderId);
			formsInFolder.forEach(form => this.updateFormLocally(form.Id, { IsArchived: false }));
			this.updateFolderLocally(folderId, { IsArchived: false });
		});
	},

	async moveForms(formIds: string[], folderId: string = null) {
		return moveForms(formIds, folderId).then(() => {
			formIds.forEach(formId => {
				this.updateFormLocally(formId, { FolderId: folderId });
			});
		});
	},

	async deleteFolder(folderId: string) {
		return deleteFolder(folderId).then(() => {
			Vue.delete(globalState.folders, folderId);
			if (globalState.allFolders[folderId])
				Vue.delete(globalState.allFolders, folderId);
		});
	},

	async deleteForm(formId: string) {
		return deleteForm(formId).then(() => {
			Vue.delete(globalState.forms, formId);
			if (globalState.allForms[formId])
				Vue.delete(globalState.allForms, formId);
		});
	},

	async deleteView(viewId: string) {
		const formId = viewId.split('-')[0];
		return deleteEntryView(formId, viewId).then(() => {
			Vue.delete(globalState.forms[formId].EntryViews, globalState.forms[formId].EntryViews.findIndex(v => v.Id === viewId));
		});
	}
});

export type GlobalStore = typeof globalStore;
