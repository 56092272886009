import Router from 'vue-router';
import AuthRoutes from './auth/_routes';
import IntegrationRoutes from './integrations/_routes';
import ImporterRoutes from './importers/_routes';
import AdminRoutes from './admin/_routes';
import SettingsRoutes from './settings/_routes';
import { interceptResponses, isServiceRequestError } from '@cognitoforms/api/services/service-request';
import { convertAbsoluteUrlToRelative } from '@cognitoforms/utils/url-helper';
import { getActiveSession } from '@cognitoforms/api/services/session';
import { reportReferral } from '@cognitoforms/api/services/referral';
import Root from './Root.vue';
import { isPrerender } from '@cognitoforms/utils/utilities';
import { PerfMarks, PerfMetrics } from 'src/utilities/perf-monitoring';
import { AppInsights } from '@cognitoforms/shared-components/src/components/embeds/AppInsights';
import type { NavigationGuardNext, RawLocation, Route } from 'vue-router';
import type VueRouter from 'vue-router';
import { globalStore } from 'src/stores/global-store';

export async function handlePageAccessError(error: Error, to: Route, next: NavigationGuardNext) {
	if (isServiceRequestError(error)) {
		if (error.statusCode === 401) {
			let returnUrl = getCurrentPage();
			if (error.referrerUrl)
				returnUrl = convertAbsoluteUrlToRelative(error.referrerUrl);

			loginAndRedirect(returnUrl);
		}
		else {
			const session = await getActiveSession();
			if (session.enabledFeatures.AppNav) {
				next({
					name: 'forms',
					params: {
						orgcode: to.params.orgcode,
						pagePermissionDenied: String(error.statusCode === 403 || error.statusCode === 404)
					}
				});
			}
			// TODO: Remove this when AppNav works
			// This is needed because without it we won't be able to redirect back to the same page we're already on (vue router problem)
			else {
				location.assign(`/${session.orgCode}`);
			}
		}
	}
	else
		next(error);
}

// If the URL starts with `//`, this causes an error in the browser when Vue router calls `replaceState`
// DOMException: Failed to execute 'replaceState' on 'History': A history state object with URL 'https:/...
// So, detect this and remove the duplicate leading `/` if needed
// NOTE: Loosely based on some code in VueRouter...
function fixUrl() {
	const protocolAndPath = window.location.protocol + '//' + window.location.host;
	let absolutePath = window.location.href.replace(protocolAndPath, '');
	if (/^\/\//.test(absolutePath)) {
		// Trim off the duplicate leading '/'
		absolutePath = absolutePath.substring(1);
		window.history.replaceState({}, '', protocolAndPath + absolutePath);
	}
}

function getCurrentPage() {
	return location.pathname + location.search;
}

function loginAndRedirect(returnUrl = undefined) {
	if (!returnUrl)
		returnUrl = getCurrentPage();
	location.assign(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);
}

let historyStateOnLoad;
export function hasPreviousHistory() {
	return window.history.state?.key !== historyStateOnLoad;
}

const createRouter = () => {
	if (!isPrerender()) fixUrl();

	const router = new Router({
		mode: 'history',
		base: '/',
		scrollBehavior(to, from, savedPosition) {
			// When filtering template groupings via radio buttons, don't scroll
			const templateGroupings = ['industry', 'type', 'feature'];
			if (!to.hash && templateGroupings.includes(to.params.grouping) && to.name === 'template-index' && from.name === 'template-index') {//
				return null;
			}
			else if (!to.hash && from.name !== 'template-index' && to.matched.length > 1 && (to.matched[1].name || '') === 'template-with-tag-details') {
				if (document.querySelector('h1').getBoundingClientRect().top > -20)
					return null;
				return window.scrollTo({ top: 0, behavior: 'smooth' });
			}
			else if (to.hash) {
				// Set the offset when scroll target uses scroll-margin-top css
				const target = document.getElementById(to.hash.substring(1));
				if (target) {
					const targetStyle = window.getComputedStyle(target);
					const offsetAmt = parseInt(targetStyle.getPropertyValue('scroll-margin-top'));
					const targetOffset = { x: 0, y: offsetAmt };
					return { selector: to.hash, offset: targetOffset };
				}
			}
			else if (savedPosition && !to.hash.length) {
				return null;
			}
			else {
				return window.scrollTo({ top: 0, behavior: 'auto' });
			}
		},
		routes: [
			...AuthRoutes,
			...IntegrationRoutes,
			...ImporterRoutes,
			...SettingsRoutes,
			{
				path: '',
				component: Root,
				props: true,
				children: [
					{
						path: '/auth',
						component: () => import(/* webpackChunkName: 'Auth' */ 'src/layouts/Auth.vue'),
						children: [
							{
								path: '/signup/create',
								name: 'create-industry-organization',
								component: () => import(/* webpackChunkName: 'Auth' */ './CreateIndustryOrganization.vue'),
								props: true,
								meta: {
									auth: 'required',
									authType: 'signup',
									loadManifest: true
								}
							}
						]
					},
					...AdminRoutes
				]
			},
			{
				path: '*',
				name: '404',
				component: () => import(/* webpackChunkName: 'PageNotFound' */ './PageNotFound.vue')
			}
		]
	});

	router.onReady(() => {
		if (!isPrerender() && !router.currentRoute.meta.auth && document.referrer !== location.href)
			reportReferral();

		historyStateOnLoad = window.history.state.key;
	});

	router.beforeEach(async (to, from, next) => {
		if (to.meta.customReadyEvent) {
			performance.clearMarks(PerfMarks.RouteStart);
			performance.mark(PerfMarks.RouteStart, { detail: { to: to.fullPath, routeName: to.name } });
		}

		let data;

		// Redirect to /login page for routes that require authentication if the user is not logged in
		// Reference: https://router.vuejs.org/guide/advanced/meta.html
		if (to.matched.some(record => record.meta.auth)) {
			const session = await getActiveSession();
			const isAuthenticated = session.isAuthenticated;

			// Attempt to validate or establish the user's session
			if (!isAuthenticated && to.matched.some(record => record.meta.auth === 'required')) {
				let path = '/login';

				if (to.matched.some(record => record.meta.authType === 'signup'))
					path = '/signup';

				data = {
					path: path,
					query: { returnUrl: to.fullPath }
				};
			}
			else if (isAuthenticated && to.matched.some(record => record.path === '/signup/create')) {
				// If session has an org code, user should not have gotten here and can not create organization from the signup route.
				// Redirect to home page
				if (session.orgCode)
					data = {
						path: `/${session.orgCode}`,
						query: { returnUrl: to.fullPath }
					};
			}
			else if (isAuthenticated && session && !session.orgCode && to.matched.some(record => record.path !== '/organization/new')) {
				// Redirect to create new organization
				data = {
					path: '/signup/create',
					query: { returnUrl: to.fullPath }
				};
			}
		}

		data ? next(data) : next();
	});

	// Handle errors that occur during router navigation
	// Reference: https://router.vuejs.org/api/#router-onerror
	router.onError(async function handleRouteNavigationError(err) {
		// If a service request fails with a 401 (Unauthorized) error during navigation,
		// then this means that the user is not logged in, so redirect to the login page
		if (isServiceRequestError(err) && err.statusCode === 401) {
			// Return to the current page by default
			let returnUrl = getCurrentPage();
			// Since Vue router has already changed (reverted) the window location by the time the error
			// handler fires, use the URL of the page that made the service request that failed
			if (err.referrerUrl)
				returnUrl = convertAbsoluteUrlToRelative(err.referrerUrl);

			loginAndRedirect(returnUrl);
		}
		// Seperate the 403 page out when implemented in vue
		else if (isServiceRequestError(err) && err.statusCode === 403) {
			location.assign('/404');
		}
	});

	interceptResponses(async response => {
		if (response.status === 401) {
			loginAndRedirect();
		}
	});

	if (typeof window !== 'undefined') {
		window?.addEventListener('mouseup', evt => {
			if ((evt.target as Element).closest('a[href]')) {
				performance.clearMarks(PerfMarks.LinkClick);
				performance.mark(PerfMarks.LinkClick);
			}
		});

		const perfObserver = new PerformanceObserver(perfEntries => {
			const routeStart = performance.getEntriesByName(PerfMarks.RouteStart).at(-1) as PerformanceMark;
			const lastClick = performance.getEntriesByName(PerfMarks.LinkClick).at(-1) as PerformanceMark;
			const startingMark = lastClick && (lastClick.startTime > routeStart?.startTime) ? lastClick : routeStart;

			const pageReady = performance.getEntriesByName(PerfMarks.PageReady).at(-1) as PerformanceMark;
			if (pageReady) {
				const measure = performance.measure(`${PerfMetrics.TimeToPageReady} - ${routeStart.detail.routeName}`, startingMark.name, PerfMarks.PageReady);
				if (process.env.NODE_ENV === 'development')
					console.log(measure);
				AppInsights()?.trackMetric({ name: measure.name, average: measure.duration, properties: pageReady.detail });
				performance.clearMarks(PerfMarks.PageReady);
			}
		});
		perfObserver.observe({ type: 'mark' });
	}

	return router;
};

export default createRouter;

export function getFallbackRoute(router: VueRouter): RawLocation {
	const getRoute: (r: VueRouter) => RawLocation = router.currentRoute.matched.findLast(r => typeof r.meta.getFallbackRoute === 'function')?.meta.getFallbackRoute;
	if (getRoute)
		return getRoute(router);
	else {
		const orgCode = router.currentRoute.params.orgcode ?? globalStore.organization.Code;
		return `/${orgCode}`;
	}
}